.ant-steps-item-container {
  display: flex;
  flex-flow: column;
}
.ant-steps-item-title {
  font-size: 12px !important;
  width: 100%;
  display: block !important;
  white-space: normal;
  line-height: 1.4 !important;
  text-align: center;
  margin-top: 5px !important;
}

.ant-steps.ant-steps-horizontal:not(.ant-steps-label-vertical)
  :is(.ant-steps-item, .ant-steps-item-icon) {
  padding: 0 !important;
  margin: 0 !important;
  flex: 1 1 100%;
  overflow: visible;
}

.ant-steps.ant-steps-horizontal:not(.ant-steps-label-vertical)
  .ant-steps-item-title {
  padding: 0 !important;
  margin: 5px 0 0 !important;
  font-family: "Inter";
  font-weight: 500;
}

.ant-steps-icon {
  /* background-color: blue; */
  width: 40px !important;
  height: 40px !important;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: #eee;
  /* color: #fff; */
  svg {
    width: 18px;
    height: 18px;
  }
}

.ant-steps
  :is(.ant-steps-item-process.ant-steps-item-custom, .ant-steps-item-finish)
  .ant-steps-item-icon
  > .ant-steps-icon {
  background: #103778;
  color: #fff;
}

.ant-steps.ant-steps-horizontal:not(.ant-steps-label-vertical)
  .ant-steps-item-tail {
  display: block;
  top: 20px;
  left: 75%;
  width: 50%;
}

.ant-steps .ant-steps-item-tail::after {
  height: 4px;
  border-radius: 4px;
}
.ant-steps
  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background: #103778;
}
.ant-steps-item-title:after {
  display: none !important;
}
